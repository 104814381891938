import Image from "next/image";
import { MinProductItemProps } from "./constants";
import HorizontalPwPProduct from "./HorizontalPwPProduct";


interface PwPProps {
  pwp_name: string | null;
  pwp: Array<MinProductItemProps>;
  setSelectProduct: (product: MinProductItemProps) => void;
  viewport: string | undefined;
}

const HorizontalPwP = (props: PwPProps) => {

  return (
    <div className="mt-4">
      <div className={props.viewport === "desktop" ? "" : "container"}>
        <div className="flex items-start justify-center py-2 space-x-4 text-white rounded md:items-center bg-mooimom">
          <Image
            src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/percent.png"}
            alt="percent icon"
            quality={100}
            width={16}
            height={16}
          />
          <p className="text-xs font-bold md:text-sm font-poppins">{props.pwp_name !== null ? props.pwp_name : "Paket Diskon"}</p>
        </div>
      </div>
      <div className="flex w-full pl-6 mt-4 space-x-4 overflow-x-auto md:pl-0 overflow-y-none flex-nowrap">
        {props.pwp.map((product, index) => (
          <div key={index} className="min-w-[240px] md:min-w-[300px]">
            <HorizontalPwPProduct product={product} setSelectProduct={props.setSelectProduct} style="cart" />
          </div>
        ))}
      </div>
    </div>
  )
}

export default HorizontalPwP
