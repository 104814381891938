import Link from "next/link";
import Image from 'next/image';
import { CategoryItem } from "@/lib/constants";
import { useEffect, useState } from "react";
import SecondMobileMenu from "./SecondMobileMenu";
import { User } from '@/stores/initialState';
import { NextRouter } from "next/router";


interface CategoryListItemProps {
  categories: Array<CategoryItem>;
  isMenuOpen: boolean;
  userData: User;
  router: NextRouter;
}

const MobileMenuItem = (props: CategoryListItemProps) => {
  const { categories, isMenuOpen, userData, router } = props;
  const [showMenu, setShowMenu] = useState<number>(0)
  const [isOpen, setIsOpen] = useState(false);
  const [category, setCategory] = useState<CategoryItem | null>(null);

  const handleShowMenu = (id: number) => {
    const cc = categories.find(category => category.id === id);

    if (cc !== undefined && cc !== null) {
      setCategory(cc);
      setIsOpen(true);
    }
  }

  useEffect(() => {
    if (!isMenuOpen) {
      setIsOpen(false);
    }
  }, [isMenuOpen])

  const specialCategories = categories.filter(category => category.type === "Special");
  const mainCategories = categories.filter(category => category.type === "A");
  const brandCategories = categories.filter(category => category.type === "BRAND");

  const render_special_categories = () => {
    return specialCategories.map((category, index) => {
      if (index === 0) {
        return (
          <div key={index} className="text-lg font-bold">{category.name}</div>
        )
      } else if (index === specialCategories.length - 1) {
        return (
          <div key={index}>
            <div className="mx-2 my-6 text-sm">
              <Link href={category.name === "Promotions" ? `/${category.slug}` :`/product-category/${category.slug}`}>
                {category.name}
              </Link>
            </div>
            {/* <div className="mx-2 my-6 text-sm">Event</div> */}
            <hr className="my-6 bg-reviewBg" />
          </div>
        )
      } else {
        return (
          <div key={index} className="mx-2 my-6 text-sm">
            <Link href={category.name === "Promotions" ? `/${category.slug}` :`/product-category/${category.slug}`}>
              {category.name}
            </Link>
          </div>
        )
      }
    })
  }

  const render_custom_categories = () => {
    return (
      <>
        <div
          className="text-lg font-bold"
          onClick={() => handleShowMenu(brandCategories.length > 0 ? brandCategories[0].id : 0)}
        >
          Brands
        </div>
        <hr className="my-6 bg-reviewBg" />
        <div className="text-lg font-bold">
          <Link href={userData.token === "" ? "/login?redirect=https://affiliate.mooimom.id" : "https://affiliate.mooimom.id?uid=" + userData.token} target="_blank">
            Program Afiliasi
          </Link>
        </div>
        <hr className="my-6 bg-reviewBg" />
        <div className="text-lg font-bold">
          <Link href="/mamapedia-homepage">Mamapedia</Link>
        </div>
        <hr className="my-6 bg-reviewBg" />
        <div className="text-lg font-bold">Membership</div>
        <div className="mx-2 my-6 text-sm">
          <Link href="/rewards">MOOIMOM Rewards</Link>
        </div>
        <div className="mx-2 my-6 text-sm">
          <Link href="/user-warranty-info">Garansi Produk</Link>
        </div>
      </>
    )
  }

  return (
    <div className="w-full overflow-y-auto mega-menu-height font-poppins text-mobileMenu">
      <div className="container">
        {specialCategories.length > 0 && render_special_categories()}
        {mainCategories.length > 0 && (
          <>
            <div className="text-lg font-bold">Kategori</div>
            {mainCategories.map((category, index) => (
              <div
                key={index}
                className="flex items-center justify-between mx-2 my-6 text-sm"
                onClick={() => handleShowMenu(category.id)}
              >
                <p>{category.name}</p>
                <Image
                  src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/arrow_right.png"}
                  alt="arrow icon"
                  width={14}
                  height={14}
                  quality={100}
                  className={`cursor-pointer`}
                />
              </div>
            ))}
            <hr className="my-6 bg-reviewBg" />
          </>
        )}
        {render_custom_categories()}
        <SecondMobileMenu isOpen={isOpen} setIsOpen={setIsOpen} category={category} type={category?.type || "A"} />
        {/* {categories.filter(category => category.type === "A").map((category, index) => {
          if (category.subcategory.filter(category_b => category_b.type === "B" || category_b.type === "BRAND").length === 0) {
            return (
              <div className="py-6 border-b border-reviewBg" key={index}>
                <div
                  className="flex items-center justify-between text-base leading-6"
                  onClick={() => handleShowMenu(category.id)}
                >
                  <p>{category.name}</p>
                  <Image
                    src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/arrow_right.png"}
                    alt="minus icon"
                    width={14}
                    height={14}
                    quality={100}
                    className={`cursor-pointer`}
                  />
                </div>
                <motion.div
                  layout
                  style={{ height: showMenu !== category.id ? "0px" : "100%"}}
                  transition={{ duration: 0.3 }}
                  className="overflow-hidden"
                >
                  {category.subcategory.filter(category_b => category_b.type != "B" && category_b.type != "BRAND").map((category_b, index) => (
                    <Link href={category_b.link !== null ? category_b.link : "/"} key={index}>
                      <div className="mx-2">
                        <p className="mt-4 text-sm text-mobileMenu">{category_b.name}</p>
                      </div>
                    </Link>
                  ))}
                </motion.div>
              </div>
            )
          } else if (category.subcategory.filter(category_b => category_b.type === "BRAND").length > 0) {
            return (
              <div className="py-6" key={index}>
                <div className="flex items-center justify-between text-base font-bold leading-6">
                  {category.name}
                </div>
                {category.subcategory.filter(category_b => category_b.type === "BRAND").map((category_b, index) => (
                  <div className="" key={index}>
                    <div
                      className="flex items-center justify-between mx-2 mt-6 text-base font-normal"
                      onClick={() => handleShowMenu(category_b.id)}
                    >
                      <p>{category_b.name.split("-", 1)}</p>
                      <Image
                        src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/arrow_right.png"}
                        alt="minus icon"
                        width={14}
                        height={14}
                        quality={100}
                        className={`cursor-pointer`}
                      />
                    </div>
                    <motion.div
                      layout
                      style={{ height: showMenu !== category_b.id ? "0px" : "100%"}}
                      transition={{ duration: 0.3 }}
                      className="mx-4 overflow-hidden"
                    >
                      <Link href={`/product-category/${category.slug}/${category_b.slug}`}>
                        <p className="mt-6 text-sm text-mobileMenu" key={index}>Shop All</p>
                      </Link>
                      {category_b.subcategory.map((category_c, index) => (
                        <Link href={`/product-category/${category.slug}/${category_b.slug}/${category_c.slug}`} key={index}>
                          <p className="mt-6 text-sm text-mobileMenu">{category_c.name}</p>
                        </Link>
                      ))}
                    </motion.div>
                  </div>
                ))}
                <div className="grid grid-cols-2 mt-5 gap-x-6">
                  {category.subcategory.filter(category_b => category_b.type === "BRAND").map((category_b, index) => (
                    <div className="w-full min-h-[100px] flex items-center justify-center" key={index}>
                      <Link href={"/brand/" + category_b.slug}>
                        <Image
                          src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/brand/logo-" + category_b.slug + "-middle.png"}
                          alt={category_b.name + " image"}
                          width={500}
                          height={245}
                          quality={100}
                          className={`w-[150px]`}
                        />
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            )
          } else {
            return (
              <div className="py-6 border-b border-reviewBg" key={index}>
                <div className="text-lg font-bold leading-6">
                  {category.name}
                </div>
                {category.subcategory.filter(category_b => category_b.type === "B").map((category_b, index) => (
                  <div className="" key={index}>
                    <div
                      className="flex items-center justify-between mx-2 mt-6 text-base font-normal"
                      onClick={() => handleShowMenu(category_b.id)}
                    >
                      <p>{category_b.name}</p>
                      <Image
                        src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/arrow_right.png"}
                        alt="minus icon"
                        width={14}
                        height={14}
                        quality={100}
                        className={`cursor-pointer`}
                      />
                    </div>
                    <motion.div
                      layout
                      style={{ height: showMenu !== category_b.id ? "0px" : "100%"}}
                      transition={{ duration: 0.3 }}
                      className="mx-4 overflow-hidden"
                    >
                      <Link href={`/product-category/${category.slug}/${category_b.slug}`}>
                        <p className="mt-6 text-sm text-mobileMenu" key={index}>Shop All</p>
                      </Link>
                      {category_b.subcategory.map((category_c, index) => (
                        <Link href={`/product-category/${category.slug}/${category_b.slug}/${category_c.slug}`} key={index}>
                          <p className="mt-6 text-sm text-mobileMenu">{category_c.name}</p>
                        </Link>
                      ))}
                    </motion.div>
                  </div>
                ))}
              </div>
            )
          }
        })} */}
      </div>
    </div>
  )
}

export default MobileMenuItem
