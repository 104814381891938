import { create } from "zustand";
import { PersistOptions, persist } from "zustand/middleware";
import { produce } from "immer";
import CryptoJS from 'crypto-js';
import { Categories, CategoryState, initCategories } from "./initialState";

export const useCategoriesStore = create<CategoryState>() (
  persist(
    (set, get) => ({
      categories: CryptoJS.AES.encrypt(JSON.stringify(initCategories), process.env.NEXT_PUBLIC_HASH_CODE!).toString(),
      updateCategories: (categories: Categories) => {
        set(
          produce((draft: CategoryState) => {
            const encryptedUser = CryptoJS.AES.encrypt(JSON.stringify(categories), process.env.NEXT_PUBLIC_HASH_CODE!).toString();
            draft.categories = encryptedUser;
          })
        )
      },
      getCategories: () => {
        const state = get();

        if (state.categories.constructor === ({}).constructor) {
          set(
            produce((draft: CategoryState) => {
              const encryptedUser = CryptoJS.AES.encrypt(JSON.stringify(state.categories), process.env.NEXT_PUBLIC_HASH_CODE!).toString();
              draft.categories = encryptedUser;
            })
          )

          return state.categories;
        } else if (state.categories.constructor === "text".constructor) {
          try {
            return JSON.parse(CryptoJS.AES.decrypt(state.categories, process.env.NEXT_PUBLIC_HASH_CODE!).toString(CryptoJS.enc.Utf8));
          } catch (error) {
            return initCategories;
          }
        } else {
          return initCategories;
        }
      }
    }), {
      name: "categories"
    } as PersistOptions<CategoryState>
  )
)
