import { TopTextItemProps } from "@/lib/constants";
import Cookies from "js-cookie";
import Link from "next/link";
import Marquee from "react-fast-marquee";


interface TopTextProps {
  top_text_list: Array<TopTextItemProps>;
}

const TopText = (props: TopTextProps) => {
  const viewport = Cookies.get('viewport');

  return (
    <div className="h-8 py-1 bg-topTextBg md:bg-mooimom">
      <Marquee
        speed={40}
      >
        {props.top_text_list.map((top_text, index) => (
          <div key={index} className="text-mooimom md:text-white font-poppins text-xs font-bold mr-[120px]">
            {top_text.link !== null && top_text.link.length > 0 ? (
              <Link href={top_text.link}>
                <span className="leading-6" dangerouslySetInnerHTML={{__html:top_text.text}}></span>
              </Link>
            ) : (
              <span className="leading-6" dangerouslySetInnerHTML={{__html:top_text.text}}></span>
            )}
          </div>
        ))}
      </Marquee>
    </div>
  )
}

export default TopText;
