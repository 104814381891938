import { create } from "zustand";
import { PersistOptions, persist } from "zustand/middleware";
import { produce } from "immer";
import CryptoJS from 'crypto-js';
import { SuggestSearch, SuggestSearchState, initSuggestSearch } from "./initialState";

export const useSuggestSearchStore = create<SuggestSearchState>() (
  persist<SuggestSearchState>(
    (set, get) => ({
      suggestSearch: CryptoJS.AES.encrypt(JSON.stringify(initSuggestSearch), process.env.NEXT_PUBLIC_HASH_CODE!).toString(),
      updateSuggestSearch: (suggestSearch: SuggestSearch) => {
        set(
          produce((draft: SuggestSearchState) => {
            const encryptedUser = CryptoJS.AES.encrypt(JSON.stringify(suggestSearch), process.env.NEXT_PUBLIC_HASH_CODE!).toString();
            draft.suggestSearch = encryptedUser;
          })
        )
      },
      getSuggestSearch: () => {
        const state = get();

        if (state.suggestSearch.constructor === ({}).constructor) {
          set(
            produce((draft: SuggestSearchState) => {
              const encryptedUser = CryptoJS.AES.encrypt(JSON.stringify(state.suggestSearch), process.env.NEXT_PUBLIC_HASH_CODE!).toString();
              draft.suggestSearch = encryptedUser;
            })
          )

          return state.suggestSearch;
        } else if (state.suggestSearch.constructor === "text".constructor) {
          try {
            return JSON.parse(CryptoJS.AES.decrypt(state.suggestSearch, process.env.NEXT_PUBLIC_HASH_CODE!).toString(CryptoJS.enc.Utf8));
          } catch (error) {
            return initSuggestSearch;
          }
        } else {
          return initSuggestSearch;
        }
      }
    }), {
      name: "suggestSearch"
    } as PersistOptions<SuggestSearchState>
  )
)
