import Image from "next/image"
import { MinProductItemProps } from "./constants"
import Link from 'next/link';
import { get_original_price, get_sales_price } from "@/lib/Definitions";


interface PwPProductProps {
  product: MinProductItemProps;
  setSelectProduct: (product: MinProductItemProps) => void;
  style: string;
}

const HorizontalPwPProduct = (props: PwPProductProps) => {
  const { product } = props;

  const show_sales_price = () => {
    const sales_price = get_sales_price(product, null);

    if (Array.isArray(sales_price.price)) {
      return (
        <p className={`flex text-xs font-semibold text-price`}>
          {process.env.NEXT_PUBLIC_CURRENCY}{sales_price.price[0].toLocaleString()} ~ {process.env.NEXT_PUBLIC_CURRENCY}{sales_price.price[1].toLocaleString()}
        </p>
      )
    } else {
      return (
        <p className={`flex text-xs font-semibold ${sales_price.promotion ? "text-mooimom" : "text-price"}`}>
          {process.env.NEXT_PUBLIC_CURRENCY}{sales_price.price.toLocaleString()}
        </p>
      )
    }
  }

  const show_origin_price = () => {
    const origin_price = get_original_price(product, null);

    if (origin_price === null) {
      return (<></>)
    } else {
      return (
        <p className="text-xs line-through text-originPrice">
          {process.env.NEXT_PUBLIC_CURRENCY}{origin_price.toLocaleString()}
        </p>
      )
    }
  }

  return (
    <div className="grid grid-cols-3 p-3 border border-solid rounded-lg gap-x-4 border-attributeBorder">
      <Image
        src={product.image}
        alt={product.name + " image"}
        quality={100}
        width={800}
        height={800}
        className="object-contain aspect-square"
      />
      <div className="col-span-2 font-poppins">
        <Link href={`/product/${product.slug}`}>
          <p className="h-4 mt-2 text-xs text-cartProductName line-clamp-1 text-ellipsis">{product.name}</p>
        </Link>
        <div className="flex items-center justify-between mt-2">
          <div>
            {show_sales_price()}
            {show_origin_price()}
          </div>
          <button
            className="px-1 text-2xl font-semibold leading-none text-white rounded-md aspect-square bg-mooimom"
            onClick={() => props.setSelectProduct(product)}
          >
            +
          </button>
        </div>
      </div>
    </div>
  )
}

export default HorizontalPwPProduct
