import { Swiper, SwiperSlide } from "swiper/react";
import { MinProductItemProps } from "./constants";
import Link from "next/link";
import Image from 'next/image';
import { get_sales_price } from "@/lib/Definitions";


interface RecommendProps {
  recommend: Array<MinProductItemProps>;
  recommend_title: string | null;
  recommend_icon: string | null;
  recommend_title_style: string;
  setSelectProduct: (product: MinProductItemProps) => void;
}

const Recommend = (props: RecommendProps) => {

  const show_sales_price = (product: MinProductItemProps) => {
    const sales_price = get_sales_price(product, null);

    if (Array.isArray(sales_price.price)) {
      return (
        <p className={`text-xs md:text-sm font-semibold text-cartProductName`}>
          {process.env.NEXT_PUBLIC_CURRENCY}{sales_price.price[0].toLocaleString()} ~
        </p>
      )
    } else {
      return (
        <p className={`text-xs md:text-sm font-semibold text-cartProductName`}>
          {process.env.NEXT_PUBLIC_CURRENCY}{sales_price.price.toLocaleString()}
        </p>
      )
    }
  }

  return (
    <div className="py-4 bg-topTextBg md:hidden z-[5] mb-10">
      <div className={`flex ${props.recommend_title_style}`}>
        {props.recommend_icon !== null && props.recommend_icon.length > 0 && (
          <Image
            src={props.recommend_icon}
            alt="icon"
            quality={100}
            width={16}
            height={16}
          />
        )}
        {props.recommend_title !== null && props.recommend_title.length > 0 && (
          <p className="mx-6 mb-2 text-sm font-bold text-black font-poppins">{props.recommend_title}</p>
        )}
      </div>
      <Swiper
        spaceBetween={24}
        centeredSlides={false}
        loop={true}
        slidesPerView={1.4}
        className="!pl-6"
      >
        {props.recommend.map((recommend, index) => (
          <SwiperSlide key={index}>
            <Link href={`/product/${recommend.slug}`}>
              <div className="flex space-x-2">
                <Image
                  src={recommend.image}
                  alt={recommend.name + " image"}
                  quality={100}
                  width={78}
                  height={78}
                  className="mx-auto aspect-square"
                />
                <div className="">
                  <p className="text-xs text-cartProductName line-clamp-1 text-ellipsis">{recommend.name}</p>
                  <div className="flex items-center justify-between">
                    {show_sales_price(recommend)}
                    <button
                      className="z-10 p-1 cursor-pointer w-fit h-fit"
                      onClick={(event) => {
                        event?.preventDefault();
                        props.setSelectProduct(recommend);
                      }}
                    >
                      <Image
                        src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/cart-plus.png"}
                        alt="add to cart icon"
                        width={20}
                        height={20}
                        quality={100}
                        className=""
                      />
                    </button>
                  </div>
                </div>
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default Recommend
