import { useCallback, useEffect, useState } from "react";
import Image from 'next/image';
import axios from "axios";
import _ from 'lodash';
import { MinProductItemProps } from "../Product/constants";
import Link from "next/link";
import { get_original_price, get_sales_price } from "@/lib/Definitions";
import { useSuggestSearchStore } from "@/stores/useSuggestSearchStore";
import { useRouter } from "next/router";


type SearchResult = {
  keywords: Array<string>;
  products: Array<MinProductItemProps>;
}

interface SearchProps {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
}

const Search = (props: SearchProps) => {
  const router = useRouter();
  const { isVisible, setIsVisible } = props;
  const { suggestSearch, getSuggestSearch } = useSuggestSearchStore();
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResult, setSearchResult] = useState<SearchResult | null>(null);
  const [isSearched, setIsSearched] = useState(false);
  const [suggestSearchData, setSuggestSearchData] = useState(getSuggestSearch());

  useEffect(() => {
    setSuggestSearchData(getSuggestSearch());
  }, [getSuggestSearch, suggestSearch])

  useEffect(() => {
    setIsOpen(isVisible);
  }, [setIsOpen, isVisible])

  useEffect(() => {
    isOpen ? (document.body.style.overflow = 'hidden') : (document.body.style.removeProperty('overflow'));
  }, [isOpen])

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setSearchTerm(value);
    delayedSearch(value);
  }

  const show_sales_price = (product: MinProductItemProps) => {
    const sales_price = get_sales_price(product, null);

    if (Array.isArray(sales_price.price)) {
      return (
        <p className={`flex font-semibold text-price text-xs`}>
          {process.env.NEXT_PUBLIC_CURRENCY}{sales_price.price[0].toLocaleString()} ~
        </p>
      )
    } else {
      return (
        <p className={`flex font-semibold text-xs ${sales_price.promotion ? "text-mooimom" : "text-price"}`}>
          {process.env.NEXT_PUBLIC_CURRENCY}{sales_price.price.toLocaleString()}
        </p>
      )
    }
  }

  const show_origin_price = (product: MinProductItemProps) => {
    const origin_price = get_original_price(product, null);

    if (origin_price === null) {
      return (<></>)
    } else {
      return (
        <p className="font-medium text-[10px] line-through text-originPrice">
          {process.env.NEXT_PUBLIC_CURRENCY}{origin_price.toLocaleString()}
        </p>
      )
    }
  }

  const delayedSearch = useCallback(
    _.debounce(async (value: string) => {
      if (value.length >= 3) {
        try {
          const response = await axios.get('/api/keyword_search', {
            params: { search: value },
          });

          if (response.data.status === "ok") {
            delete response.data.status;
            setSearchResult(response.data);
          } else {
            setSearchResult(null);
          }

          setIsSearched(true);
        } catch (error) {
          setSearchResult(null);
          setIsSearched(true);
        }
      } else if (value.length === 0) {
        setSearchResult(null);
        setIsSearched(true);
      }
    }, 1000),
    []
  );

  return (
    <>
      <div
        className={`${isOpen ? "fixed left-0 w-screen h-screen top-0 right-0 bottom-0 bg-black opacity-30 z-[54]" : ""}`}
        onClick={() => {
          setIsOpen(!isOpen);
          setIsVisible(!isVisible);
          setIsSearched(false);
          setSearchTerm('');
          setSearchResult(null);
        }}
      ></div>
      <div
        className={`fixed w-full md:w-[468px] z-[55] bg-white font-poppins top-0 h-dscreen transition-transform ease-in-out duration-300 transform inset-y-0 -right-full md:-right-[468px] py-12 md:py-8 px-6 ${isOpen ? '-translate-x-full' : 'translate-x-0'}`}
      >
        <div className="flex items-center justify-between space-x-4 md:hidden">
          <Image
            src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/search.png"}
            alt="search icon"
            width={18}
            height={18}
            quality={100}
            onClick={() => {
              setIsSearched(false);
              setSearchTerm('');
              setSearchResult(null);
            }}
            className="cursor-pointer"
          />
          <input
            type="text"
            className="w-full py-1 text-xs text-center text-black border-0 outline-none font-poppins focus:ring-transparent"
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearch}
          />
          <Image
            src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/close.png"}
            alt="close icon"
            width={14}
            height={14}
            quality={100}
            onClick={() => {
              setIsOpen(!isOpen);
              setIsVisible(!isVisible);
              setIsSearched(false);
              setSearchTerm('');
              setSearchResult(null);
            }}
            className="cursor-pointer"
          />
        </div>
        <div className="justify-end hidden md:flex">
          <Image
            src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/close.png"}
            alt="close icon"
            width={14}
            height={14}
            quality={100}
            onClick={() => {
              setIsOpen(!isOpen);
              setIsVisible(!isVisible);
              setIsSearched(false);
              setSearchTerm('');
              setSearchResult(null);
            }}
            className="cursor-pointer"
          />
        </div>
        <div className="justify-center hidden md:flex">
          <input
            type="text"
            className="w-full py-1 text-sm text-center text-black border-0 outline-none font-poppins focus:ring-transparent"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        <hr className="mt-2 mb-6 bg-black md:my-8" />
        <div className="w-full mb-8 overflow-y-auto search-height">
          {searchResult !== null && (
            <>
              {searchResult.keywords.length > 0 && (
                <div className="flex flex-wrap items-center px-2">
                  {searchResult.keywords.map((keyword, index) => (
                    <button
                      key={index}
                      className="text-sm text-center text-white bg-review p-2 rounded-lg mr-4 mb-4"
                      onClick={() => router.push("/product-category/all-category?keyword=" + keyword)}
                      dangerouslySetInnerHTML={{ __html: keyword.replace(searchTerm, `<b>${searchTerm}</b>`) }}
                    >
                    </button>
                  ))}
                </div>
              )}
              {searchResult.products.length > 0 && searchResult.products.map((product, index) => (
                <div key={index} className={`p-4 mx-2 ${searchResult.keywords.length === 0 && index === 0 ? "mt-2" : "mt-4"} rounded shadow-[0_2px_12px_2px_rgb(0,0,0,0.1)]`}>
                  <Link href={"/product/" + product.slug}>
                    <div className={`flex items-center space-x-[10px]`}>
                      <Image
                        src={product.image}
                        alt={product.name + " image"}
                        width={60}
                        height={60}
                        quality={100}
                        className="rounded aspect-square"
                      />
                      <div className="">
                        <p className="text-xs font-medium text-black capitalize font-poppins line-clamp-2 text-ellipsis">{product.name}</p>
                        <div className="flex items-center space-x-1 mt-[10px]">
                          {show_sales_price(product)}
                          {show_origin_price(product)}
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </>
          )}
          {(searchResult === null || searchResult.keywords.length === 0) && suggestSearchData.data.keywords.length > 0 && (
            <>
              <p className={`text-sm text-black font-semibold ${searchResult !== null && searchResult.products.length > 0 ? "mt-6 md:mt-8" : ""}`}>Pencarian Terpopuler</p>
              <div className="flex flex-wrap items-center mt-4 px-2">
                {suggestSearchData.data.keywords.map((keyword, index) => (
                  <button
                    key={index}
                    className="text-sm text-center text-white bg-review p-2 rounded-lg mr-4 mb-4"
                    onClick={() => router.push("/product-category/all-category?keyword=" + keyword)}
                  >
                    {keyword}
                  </button>
                ))}
              </div>
            </>
          )}
          {suggestSearchData.data.products.length > 0 && (
            <>
              <p className={`text-sm text-black font-semibold ${suggestSearchData.data.keywords.length > 0 ? "mt-6 md:mt-8" : ""}`}>Produk Terpopuler</p>
              {suggestSearchData.data.products.map((product, index) => (
                <div key={index} className="p-4 mx-2 mt-4 rounded shadow-[0_2px_12px_2px_rgb(0,0,0,0.1)]">
                  <Link href={"/product/" + product.slug}>
                    <div className={`flex items-center space-x-[10px]`}>
                      <Image
                        src={product.image}
                        alt={product.name + " image"}
                        width={60}
                        height={60}
                        quality={100}
                        className="rounded aspect-square"
                      />
                      <div className="">
                        <p className="text-xs font-medium text-black capitalize font-poppins line-clamp-2 text-ellipsis">{product.name}</p>
                        <div className="flex items-center space-x-1 mt-[10px]">
                          {show_sales_price(product)}
                          {show_origin_price(product)}
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Search
