import { create } from "zustand";
import { PersistOptions, persist } from "zustand/middleware";
import { produce } from "immer";
import CryptoJS from 'crypto-js';
import { initRecommend, Recommend, RecommendState } from "./initialState";

export const useRecommendStore = create<RecommendState>() (
  persist<RecommendState>(
    (set, get) => ({
      recommend: CryptoJS.AES.encrypt(JSON.stringify(initRecommend), process.env.NEXT_PUBLIC_HASH_CODE!).toString(),
      updateRecommend: (recommend: Recommend) => {
        set(
          produce((draft: RecommendState) => {
            const encryptedUser = CryptoJS.AES.encrypt(JSON.stringify(recommend), process.env.NEXT_PUBLIC_HASH_CODE!).toString();
            draft.recommend = encryptedUser;
          })
        )
      },
      getRecommend: () => {
        const state = get();

        if (state.recommend.constructor === ({}).constructor) {
          set(
            produce((draft: RecommendState) => {
              const encryptedUser = CryptoJS.AES.encrypt(JSON.stringify(state.recommend), process.env.NEXT_PUBLIC_HASH_CODE!).toString();
              draft.recommend = encryptedUser;
            })
          )

          return state.recommend;
        } else if (state.recommend.constructor === "text".constructor) {
          try {
            return JSON.parse(CryptoJS.AES.decrypt(state.recommend, process.env.NEXT_PUBLIC_HASH_CODE!).toString(CryptoJS.enc.Utf8));
          } catch (error) {
            return initRecommend;
          }
        } else {
          return initRecommend;
        }
      }
    }), {
      name: "recommend"
    } as PersistOptions<RecommendState>
  )
)
