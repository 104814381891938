import Image from 'next/image';
import Link from 'next/link';
import { CategoryItem } from "@/lib/constants";


interface SecondMobileMenuProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  category: CategoryItem | null;
  type: string;
}

const SecondMobileMenu = (props: SecondMobileMenuProps) => {

  const render_promotion_menu = () => {
    return props.category?.promotions !== undefined && props.category?.promotions.length > 0 && (
      <>
        <p className="mb-4 text-base font-semibold font-poppins text-saleText">Sale</p>
        {props.category?.promotions.map((promotion, index) => (
          <Link key={index} href={`/product-category/all-promo?package=${promotion.id}`}>
            <div className={`w-full flex justify-between items-center space-x-4 ${index > 0 ? "mt-4" : ""}`}>
              <p className="text-xs font-medium text-black font-poppins">{promotion.name}</p>
              <Image
                src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/new-promo.png"}
                alt="new promo icon"
                quality={100}
                width={100}
                height={40}
                className="w-8"
              />
            </div>
          </Link>
        ))}
        <hr className="my-6 bg-reviewBg" />
      </>
    )
  }

  const render_category_menu = () => {
    return props.category?.subcategory.map((item, index) => (
      <div key={index} className="">
        <Link href={`/product-category/${props.category?.slug}/${item.slug}`}>
          <div className="flex items-center justify-between">
            <p className="text-sm font-semibold text-black">{item.name}</p>
            <Image
              src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/arrow_right.png"}
              alt="arrow icon"
              width={14}
              height={14}
              quality={100}
              className={`cursor-pointer`}
            />
          </div>
        </Link>
        <div className={`grid grid-cols-2 gap-8 justify-center items-center mt-8 ${props.category?.subcategory !== undefined && index === props.category?.subcategory.length -1 ? "pb-10" : ""}`}>
          {item.subcategory.map((subItem, index) => (
            <Link key={index} href={`/product-category/${props.category?.slug}/${item.slug}/${subItem.slug}`}>
              <div key={index} className="w-full">
                {subItem.image_icon !== null && (
                  <div className="flex justify-center w-full h-[100px]">
                    <Image
                      src={subItem.image_icon}
                      alt={subItem.name}
                      width={100}
                      height={100}
                      quality={100}
                    />
                  </div>
                )}
                <p className={`${subItem.bundle_category ? "text-review font-bold" : "text-black"} text-xs font-poppins mt-4 text-center`}>{subItem.name}</p>
              </div>
            </Link>
          ))}
        </div>
        {props.category?.subcategory !== undefined && index < props.category?.subcategory.length -1 && (<hr className="my-6 bg-reviewBg" />)}
      </div>
    ))
  }

  const render_brand_menu = () => {
    return (
      <>
        {props.category?.brands.map((group, index) => (
          <div key={index} className="flex my-4 space-x-4">
            <p className="w-4 text-sm font-bold text-black font-poppins">{group.letters}</p>
            <div className="w-full">
              {group.brands.map((brand, index2) => {
                if (index2 < group.brands.length - 1 || (props.category?.brands !== undefined && index === props.category?.brands.length - 1)) {
                  return (
                    <Link key={index2} href={`/product-category/brand?brand=${brand.name}`}>
                      <p className="mb-4 text-sm text-black font-poppins">{brand.name}</p>
                    </Link>
                  )
                } else {
                  return (
                    <>
                      <Link key={index2} href={`/product-category/brand?brand=${brand.name}`}>
                        <p key={index2} className="text-sm text-black font-poppins">{brand.name}</p>
                      </Link>
                      <hr className="mt-4 bg-reviewBg" />
                    </>
                  )
                }
              })}
            </div>
          </div>
        ))}
      </>
    )
  }

  return (
    <div className={`categories absolute w-full z-[60] bg-white top-0 h-dscreen transition-transform ease-in-out duration-300 transform inset-y-0 left-0 ${props.isOpen ? 'translate-x-0' : '-translate-x-full'}`}>
      <div
        className="container flex items-center my-6 space-x-2"
        onClick={() => props.setIsOpen(false)}
      >
        <Image
          src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/arrow_right.png"}
          alt="arrow icon"
          width={16}
          height={16}
          quality={100}
          className="transform rotate-180"
        />
        <p className="text-xs text-black font-poppins">Kembali</p>
      </div>
      <div className="container overflow-y-auto sub-mega-menu-height">
        {props.type === "A" && (
          <>
            {render_promotion_menu()}
            {render_category_menu()}
          </>
        )}
        {props.type === "BRAND" && (
          <>
            <div className="my-6 text-sm font-semibold text-center text-black">
              All Brands
            </div>
            <hr className="my-6 bg-reviewBg" />
            {render_brand_menu()}
          </>
        )}
      </div>
    </div>
  )
}

export default SecondMobileMenu;
