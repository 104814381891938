import Image from 'next/image';
import { useEffect, useState } from 'react';
import { CartProductItemProps, GiftProductItemProps } from './constants';
import axios from 'axios';
import { useUserStore } from '@/stores/useUserStore';
import { useCartsStore } from '@/stores/useCartsStore';
import { AttributeItem } from '@/lib/constants';
import { MinProductItemProps, ProductVariantItemProps } from '../Product/constants';
import Link from 'next/link';
import { event } from 'nextjs-google-analytics';
import { get_original_price, get_sales_price, getNewToken } from '@/lib/Definitions';
import HorizontalPwP from '../Product/HorizontalPwP';
import { initUser } from '@/stores/initialState';
import { useRouter } from 'next/router';
import ChooseGift from './ChooseGift';


export interface PlusCartProductItemProps extends CartProductItemProps {
  remove_cart: (cart_id: number) => void;
  show_add_to_cart: (cart_id: number) => void;
  attributes: AttributeItem[];
  setChecked: (cart_id: number, checked: boolean) => void;
  gifts: Array<GiftProductItemProps>;
  setSelectProduct: (product: MinProductItemProps) => void;
  viewport: string | undefined;
}

const CartProduct = (props: PlusCartProductItemProps) => {
  const router = useRouter();
  const { userInfo, getUser, updateUser } = useUserStore();
  const { carts, getCarts, updateCarts } = useCartsStore();
  const [isProcessing, setIsProcessing] = useState(false);
  const [displayAttr, setDisplayAttr] = useState<Array<string>>([]);
  const [variant, setVariant] = useState<ProductVariantItemProps | null>(null);
  const [userData, setUserData] = useState(getUser());
  const [cartsData, setCartsData] = useState(getCarts());
  const [gift, setGift] = useState<GiftProductItemProps | null>(null);
  const [giftVariant, setGiftVariant] = useState<ProductVariantItemProps | null>(null);
  const [giftOpen, setGiftOpen] = useState(false);

  const minus_qty = () => {
    if (props.qty > 1) {
      setIsProcessing(true);
      update_cart(props.id, props.product_variant_id, props.qty - 1)
        .then(() => {
          setIsProcessing(false);
        });
    } else {
      props.remove_cart(props.id);
    }
  }

  const plus_qty = () => {
    if (variant !== null && variant !== undefined && variant.qty > 0 && props.qty < variant.qty) {
      setIsProcessing(true);
      update_cart(props.id, props.product_variant_id, props.qty + 1)
        .then(() => {
          setIsProcessing(false);
        });
    }
  }

  const update_cart = async (cart_id: number, variant_id: number, qty: number) => {
    const data = {
      variant_id: variant_id,
      qty: qty
    }

    try {
      const response = await axios.put(
        '/api/cart/' + cart_id,
        data,
        { headers: { Authorization: 'Bearer ' + userData.token } }
      )

      if (response.data.status === "ok") {
        event("update_cart", {
          category: "ecommerce",
          value: variant?.sales_price,
          currency: process.env.NEXT_PUBLIC_CURRENCY_CODE,
          items: [
            {
              item_id: props.product_code,
              item_name: props.name,
              item_variant: variant?.product_code,
              brand: props.brand,
              price: variant?.special_price !== 0 ? variant?.special_price : props.special_price,
              quantity: qty,
              image: variant?.cart_image
            }
          ]
        });

        const idx = cartsData.data.findIndex(cart => cart.id === cart_id);

        if (idx > -1) {
          cartsData.data[idx].qty = qty;

          cartsData.update_date = Date.now();
          updateCarts(cartsData);
          setCartsData(cartsData);
        }
      }
    } catch (error: any) {
      // if (error.response.data.error === "Invalid credentials") {
      //   const response: any = await getNewToken(userData.token, userData.refresh_token, userData.uuid);

      //   if (response !== "") {
      //     userData.token = response.token;
      //     userData.refresh_token = response.refresh_token;
      //     updateUser(userData);
      //     update_cart(cart_id, variant_id, qty);
      //   }
      // }
      updateUser(initUser);
      router.push("/login?redirect=" + router.asPath);
    }
  }

  const update_gift = async () => {
    const data = {
      variant_id: props.product_variant_id,
      qty: props.qty,
      gift_product_list: [
        {
          cart_id: props.id,
          product_id: gift?.product_id,
          gift_id: giftVariant?.variant_id,
          qty: gift?.qty
        }
      ]
    }

    try {
      const response = await axios.put(
        '/api/cart/' + props.id,
        data,
        { headers: { Authorization: 'Bearer ' + userData.token } }
      )

      if (response.data.status === "ok") {
        const idx = cartsData.data.findIndex(cart => cart.id === props.id);

        if (idx > -1) {
          cartsData.data[idx].qty = props.qty;

          cartsData.update_date = Date.now();
          updateCarts(cartsData);
          setCartsData(cartsData);
        }

        setGiftOpen(false);
      }
    } catch (error: any) {
      // if (error.response.data.error === "Invalid credentials") {
      //   const response: any = await getNewToken(userData.token, userData.refresh_token, userData.uuid);

      //   if (response !== "") {
      //     userData.token = response.token;
      //     userData.refresh_token = response.refresh_token;
      //     updateUser(userData);
      //     update_cart(cart_id, variant_id, qty);
      //   }
      // }
      updateUser(initUser);
      router.push("/login?redirect=" + router.asPath);
    }
  }

  useEffect(() => {
    setUserData(getUser());
  }, [getUser, userInfo])

  useEffect(() => {
    setCartsData(getCarts());
  }, [getCarts, carts])

  useEffect(() => {
    if (gift !== null) {
      setGiftVariant(gift.product_variant_list.find((variant) => variant.variant_id === gift.product_variant_id) || null);
      setGiftOpen(true);
    }
  }, [gift])

  useEffect(() => {
    if (!giftOpen) {
      setGift(null);
      setGiftVariant(null);
    }
  }, [giftOpen])

  useEffect(() => {
    const variant = props.product_variant_list.find((variant) => variant.variant_id === props.product_variant_id);

    if (variant) {
      setVariant(variant);
      const attr_list = [];

      for (var i = 0; i < props.attributes.length; i++) {
        const filtered = props.attributes[i].attribute_values.filter((attribute) => variant.attribute_value_list.some((value) => attribute.id === value)).sort((a, b) => (a.order === b.order) ? a.id - b.id : a.order - b.order);

        switch (props.attributes[i].slug) {
          case "color":
            if (filtered.length > 0) {
              attr_list.push(filtered[0].name);
            }
            break;

          case "size":
            if (filtered.length > 0) {
              attr_list.push(filtered[0].name);
            }
            break;

          case "stage":
            if (filtered.length > 0) {
              attr_list.push(filtered[0].name);
            }
            break;

          case "pattern":
            if (filtered.length > 0) {
              attr_list.push(filtered[0].name);
            }
            break;

          case "gender":
            if (filtered.length > 0) {
              attr_list.push(filtered[0].name);
            }
            break;

          case "flavour":
            if (filtered.length > 0) {
              attr_list.push(filtered[0].name);
            }
            break;
        }
      }

      setDisplayAttr(attr_list);
    }
  }, [props.attributes, props.product_variant_id, props.product_variant_list])

  const render_display_attributes = () => {
    return displayAttr.join(", ");
  }

  const render_gift_attributes = (gift: GiftProductItemProps) => {
    const attr_list = [];
    const variant = gift.product_variant_list.find((variant) => variant.variant_id === gift.product_variant_id);

    if (variant !== null && variant !== undefined) {
      for (var i = 0; i < props.attributes.length; i++) {
        const filtered = props.attributes[i].attribute_values.filter((attribute) => variant.attribute_value_list.some((value) => attribute.id === value)).sort((a, b) => (a.order === b.order) ? a.id - b.id : a.order - b.order);

        switch (props.attributes[i].slug) {
          case "color":
            if (filtered.length > 0) {
              attr_list.push(props.attributes[i].name + " : " + filtered[0].name);
            }
            break;

          case "size":
            if (filtered.length > 0) {
              attr_list.push(props.attributes[i].name + " : " + filtered[0].name);
            }
            break;

          case "stage":
            if (filtered.length > 0) {
              attr_list.push(props.attributes[i].name + " : " + filtered[0].name);
            }
            break;

          case "pattern":
            if (filtered.length > 0) {
              attr_list.push(props.attributes[i].name + " : " + filtered[0].name);
            }
            break;

          case "gender":
            if (filtered.length > 0) {
              attr_list.push(props.attributes[i].name + " : " + filtered[0].name);
            }
            break;

          case "flavour":
            if (filtered.length > 0) {
              attr_list.push(props.attributes[i].name + " : " + filtered[0].name);
            }
            break;
        }
      }
    }

    if (attr_list.length > 0) {
      return attr_list.join(", ");
    } else {
      return (<>&nbsp;</>)
    }
  }

  const show_sales_price = () => {
    const sales_price = get_sales_price(props, null);

    if (Array.isArray(sales_price.price)) {
      return (
        <p className={`flex text-sm font-bold text-price`}>
          {process.env.NEXT_PUBLIC_CURRENCY}{sales_price.price[0].toLocaleString()} ~ {process.env.NEXT_PUBLIC_CURRENCY}{sales_price.price[1].toLocaleString()}
        </p>
      )
    } else {
      return (
        <p className={`flex text-sm font-bold ${sales_price.promotion ? "text-mooimom" : "text-price"}`}>
          {process.env.NEXT_PUBLIC_CURRENCY}{sales_price.price.toLocaleString()}
        </p>
      )
    }
  }

  const show_origin_price = () => {
    const origin_price = get_original_price(props, null);

    if (origin_price === null) {
      return (<></>)
    } else {
      return (
        <p className="text-sm font-normal line-through text-originPrice">
          {process.env.NEXT_PUBLIC_CURRENCY}{origin_price.toLocaleString()}
        </p>
      )
    }
  }

  return (
    <div>
      <div className={`flex mt-8 space-x-4 md:mt-4 ${props.viewport === "desktop" ? "" : "container"}`}>
        <input
          type="checkbox"
          className={`h-4 w-4 rounded-sm border-mooimom text-mooimom cursor-pointer focus:ring-transparent ${props.checked ? 'bg-mooimom border-transparent' : 'bg-white'}`}
          checked={props.checked}
          onChange={() => props.setChecked(props.id, !props.checked)}
        />
        <div className="min-w-[86px]">
          <Link href={"/product/" + props.slug}>
            <Image
              src={props.image}
              alt={props.image_alt}
              width={86}
              height={86}
              quality={100}
              className="object-contain border border-solid cursor-pointer aspect-square border-sku"
            />
          </Link>
        </div>
        <div className="w-full">
          <div className="grid gap-2 text-xs gird-cols-1 md:grid-cols-3 md:gap-4 text-cartProductName font-poppins">
            <div className="h-4 font-normal md:col-span-2 line-clamp-1 text-ellipsis">
              <Link href={"/product/" + props.slug}>
                {props.name}
              </Link>
            </div>
            <div
              className={`flex items-center justify-between px-2 py-1 rounded-md cursor-pointer md:col-span-3 text-black ${props.attribute_value_list.length > 0 ? "border-sku border border-solid w-fit max-w-full" : ""}`}
              onClick={() => props.show_add_to_cart(props.id)}
            >
              <p className="line-clamp-1 text-ellipsis">{render_display_attributes()}</p>
              {props.attribute_value_list.length > 0 && (
                <Image
                  src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/arrow-down.png"}
                  alt="arrow icon"
                  width={12}
                  height={12}
                  quality={100}
                  className={`transition-transform ease-in-out duration-300 transform ml-2`}
                />
              )}
            </div>
            <div className="flex items-center justify-between md:col-span-3">
              <div className="font-bold text-left text-attributeTitle">
                <Link href={"/product/" + props.slug}>
                  {show_sales_price()}
                  {show_origin_price()}
                </Link>
              </div>
              <div className="border border-sku border-solid py-1 px-2 flex justify-between items-center w-[90px]">
                <Image
                  src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/minus.png"}
                  alt="minus icon"
                  width={16}
                  height={16}
                  quality={100}
                  className="p-1 cursor-pointer"
                  onClick={() => {
                    if (!isProcessing) {
                      minus_qty();
                    }
                  }}
                />
                <input
                  type="text"
                  className="w-8 h-4 text-xs text-center text-black bg-white border-0 outline-none focus:ring-transparent"
                  value={props.qty}
                  onChange={e => {
                    if (variant !== null && variant !== undefined && variant.qty > 0) {
                      let qty = 0

                      if (parseInt(e.currentTarget.value) < 1) {
                        qty = 1;
                        props.remove_cart(props.id);
                      } else if (parseInt(e.currentTarget.value) <= variant.qty) {
                        qty = parseInt(e.currentTarget.value);
                      } else {
                        qty = variant.qty;
                      }

                      update_cart(props.id, props.product_variant_id, qty);
                    }
                  }}
                />
                <Image
                  src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/plus.png"}
                  alt="plus icon"
                  width={16}
                  height={16}
                  quality={100}
                  className="p-1 cursor-pointer"
                  onClick={() => {
                    if (!isProcessing) {
                      plus_qty();
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {props.gifts.length > 0 && (
        <div className={props.viewport === "desktop" ? "" : "container"}>
          <div className="px-4 py-2 mt-4 text-sm font-poppins bg-topTextBg md:rounded-lg md:mt-2">
            <p className="font-medium text-attributeTitle">Item Gratis</p>
            <div className="flex space-x-2 overflow-x-auto mt-4">
              {props.gifts.map((gift, index) => (
                <div key={index} className="flex items-start space-x-4 border border-reviewBg border-solid rounded-lg p-2 w-[90%] md:w-[80%]">
                  <Image
                    src={gift.image}
                    alt={gift.image_alt}
                    quality={100}
                    width={50}
                    height={50}
                    className="object-contain aspect-square"
                  />
                  <div className="space-y-2 text-xs text-black">
                    <p className="line-clamp-1 text-ellipsis">{gift.name}</p>
                    <p className="">Qty : {gift.qty}</p>
                    <p className="line-clamp-1 text-ellipsis">{render_gift_attributes(gift)}</p>
                    {gift.product_variant_list.length > 1 && (
                      <div
                        className="flex items-center justify-between space-x-2 px-2 py-1 border border-sku w-fit"
                        onClick={() => setGift(gift)}
                      >
                        <p>Pilih Tipe</p>
                        <Image
                          src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/arrow-down.png"}
                          alt="arrow icon"
                          width={12}
                          height={12}
                          quality={100}
                          className={`transition-transform ease-in-out duration-300 transform`}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {props.pwp && props.pwp.length > 0 && (
        <HorizontalPwP pwp_name={props.pwp_name} pwp={props.pwp} setSelectProduct={props.setSelectProduct} viewport={props.viewport} />
      )}
      {gift !== null && (
        <ChooseGift
          product_id={gift.product_id}
          product_variant_id={gift.product_variant_id}
          name={gift.name}
          brand={gift.brand}
          qty={gift.qty}
          image={gift.image}
          attribute_value_list={gift.whole_attribute_value_list}
          product_variant_list={gift.product_variant_list}
          variant={giftVariant}
          setVariant={setGiftVariant}
          open={giftOpen}
          setOpen={setGiftOpen}
          virtual_product={gift.virtual_product}
          need_shipping={gift.need_shipping}
          update_gift={update_gift}
        />
      )}
    </div>
  )
}

export default CartProduct
