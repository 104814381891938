import { create } from "zustand";
import { PersistOptions, persist } from "zustand/middleware";
import { produce } from "immer";
import CryptoJS from 'crypto-js';
import { CartsState, Carts, initCarts } from "./initialState";

export const useCartsStore = create<CartsState>() (
  persist<CartsState>(
    (set, get) => ({
      carts: CryptoJS.AES.encrypt(JSON.stringify(initCarts), process.env.NEXT_PUBLIC_HASH_CODE!).toString(),
      updateCarts: (carts: Carts) => {
        set(
          produce((draft: CartsState) => {
            const encryptedCarts = CryptoJS.AES.encrypt(JSON.stringify(carts), process.env.NEXT_PUBLIC_HASH_CODE!).toString();
            draft.carts = encryptedCarts;
          })
        )
      },
      getCarts: () => {
        const state = get();

        if (state.carts.constructor === ({}).constructor) {
          set(
            produce((draft: CartsState) => {
              const encryptedCarts = CryptoJS.AES.encrypt(JSON.stringify(state.carts), process.env.NEXT_PUBLIC_HASH_CODE!).toString();
              draft.carts = encryptedCarts;
            })
          )

          return state.carts;
        } else if (state.carts.constructor === "text".constructor) {
          try {
            return JSON.parse(CryptoJS.AES.decrypt(state.carts, process.env.NEXT_PUBLIC_HASH_CODE!).toString(CryptoJS.enc.Utf8));
          } catch (error) {
            return initCarts;
          }
        } else {
          return initCarts;
        }
      }
    }), {
      name: "carts"
    } as PersistOptions<CartsState>
  ) 
)
